import { useState, useEffect, Fragment, useRef } from 'react'
import {
  Flex,
  Heading,
  Text,
  Grid,
  Box,
  Input,
  useToast,
  Link as CKLink,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from '@chakra-ui/react'
import { useFormik } from 'formik'
import { string, object } from 'yup'
import { validatePhone } from '@/lib/utils/validation/phoneValidation'
import { darken } from '@chakra-ui/theme-tools'
import { useRouter } from 'next/router'

import { Link } from '@/lib/Link'
import { mq, Default, Mobile } from '@/lib/responsive'
import getErrorMsg from '@/lib/helpers/getErrorMsg'
import { Container, H2, Button, Background } from '@/components/Landing/shared'
import { sendConsultService } from '@/features/home/services'
import { logEvent } from '@/lib/stats/gtm'
import { getPolicyName } from '@/lib/helpers'

import ServiceSelection from './ServiceSelection'
import { services } from './constant'
import { TEL_REGEX } from '@/lib/utils/validation/phoneValidation'

const CheckIcon = ({ active, className, ...rest }) => {
  const image = active ? 'icons-green-check.svg' : 'icons-gray-check.svg'

  return (
    <Box
      {...rest}
      className={className}
      boxSize="24px"
      borderRadius="50%"
      pos="relative"
    >
      <Background
        src={`/images/Landing/${image}`}
        objectFit="contain"
        css={{ zIndex: 0 }}
      />
    </Box>
  )
}

const consultingServiceSchema = object().shape({
  email: string().required('จำเป็นต้องกรอกอีเมล').email('อีเมลไม่ถูกต้อง'),
  tel: string()
    .required('จำเป็นต้องกรอกเบอร์โทรศัพท์')
    .test('is-valid-phone', 'กรุณากรอกหมายเลขโทรศัพท์ที่ถูกต้อง', (value) => {
      return validatePhone(value)
    }),
})

const OurServices = ({
  primaryColor = '',
  hideHeader = false,
  hideDecor = false,
  initialService = 'privacy',
}) => {
  const { query, replace, pathname } = useRouter()
  const [selectedIndex, setSelectedIndex] = useState(() => {
    const index = services.findIndex(
      (service) =>
        service.type.toLowerCase() ===
        (query.service || initialService).toLowerCase(),
    )

    return Math.max(index, 0)
  })
  const [loading, setLoading] = useState(false)

  const isInitialized = useRef(false)

  useEffect(() => {
    if (query.service) {
      const index = services.findIndex(
        (service) => service.type.toLowerCase() === query.service.toLowerCase(),
      )

      isInitialized.current = true

      return setSelectedIndex(Math.max(index, 0))
    }
  }, [query.service])

  useEffect(() => {
    if (!isInitialized.current) return

    const service = services[selectedIndex]

    if (service.type !== query.service) {
      replace(
        {
          pathname,
          hash: '#OurServices',
          query: {
            ...query,
            service: service.type,
          },
        },
        undefined,
        {
          shallow: true,
        },
      )
    }
  }, [selectedIndex])

  const toast = useToast()

  const consultingServiceForm = useFormik({
    initialValues: {
      email: '',
      tel: '',
    },
    validationSchema: consultingServiceSchema,
    validateOnChange: false,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true)
      try {
        await sendConsultService(values.email, values.tel)
        toast({
          status: 'success',
          position: 'top-right',
          title: 'ขอบคุณที่สนใจในบริการของเรา เราจะติดต่อคุณกลับไปในไม่ช้า',
        })
        resetForm()
      } catch (err) {
        toast({
          title: getErrorMsg(err),
          status: 'error',
          position: 'top-right',
        })
      } finally {
        setLoading(false)
      }
    },
  })

  const service = services[selectedIndex]

  const planBenefitContent = service.benefit.map((benefit, index) => ({
    ...benefit,
    benefit: service.plans.map((plan) => ({
      benefit: plan.benefit[index],
      marginSize: plan.marginSize,
    })),
  }))

  return (
    <Box
      as="section"
      sx={{
        padding: {
          base: '72px 0',
          lg: '104px 0',
        },
        position: 'relative',
        width: 341,
        margin: 'auto',
        [mq('sm')]: {
          width: 719,
        },
      }}
      id="OurServices"
    >
      <Container
        css={{
          display: 'grid',
          gap: 24,
          width: '100%',
          maxWidth: '100%',
        }}
      >
        {!hideHeader && (
          <Fragment>
            <H2 css={{ [mq('lg')]: { fontSize: 40 } }}>บริการของเรา</H2>
            <ServiceSelection
              services={services}
              selectedIndex={selectedIndex}
              setSelectedIndex={(index) => {
                setSelectedIndex(index)
                consultingServiceForm.resetForm()
              }}
            />
          </Fragment>
        )}
        <Grid
          rowGap="20px"
          css={{
            w: '100%',
          }}
        >
          {!hideHeader && (
            <Fragment>
              {service.moreInfoRoute ? (
                <Link passHref route={service.moreInfoRoute}>
                  <CKLink color={service.color}>
                    <Heading
                      as="h3"
                      textAlign="center"
                      fontSize="24px"
                      fontWeight="500"
                      fontFamily="heading"
                    >
                      {service.title}
                    </Heading>
                  </CKLink>
                </Link>
              ) : (
                <Heading
                  as="h3"
                  textAlign="center"
                  fontSize="24px"
                  color={service.color}
                  fontWeight="500"
                  fontFamily="heading"
                >
                  {service.title}
                </Heading>
              )}
              <Text textAlign="center" fontSize="16px">
                {service.content.map((line, index) => (
                  <Fragment key={`content-${index}`}>
                    {line}
                    <Default>
                      <br />
                    </Default>
                    <Mobile> </Mobile>
                  </Fragment>
                ))}
              </Text>
              {service.moreInfoRoute && (
                <Link passHref route={service.moreInfoRoute}>
                  <CKLink color={service.color}>
                    <Text sx={{ textAlign: 'center' }}>
                      คลิกเพื่อดูรายละเอียดเพิ่มเติม
                    </Text>
                  </CKLink>
                </Link>
              )}
            </Fragment>
          )}
          <Grid
            templateColumns={`1fr repeat(${service.plans.length}, max-content)`}
            bg="#ffffff"
            css={{
              width: '100%',
              padding: 16,
              paddingTop: 19,
              columnGap: 19,
              borderTop: '1px solid #e8e8e8',

              [mq('sm')]: {
                padding: 24,
                columnGap: 40,
                borderRadius: '16px',
                border: '1px solid #e8e8e8',
              },
            }}
          >
            <Box>
              <Text color="#2b2b2b" fontSize="16px">
                {service.head}
              </Text>
              {service.type !== 'prokit' && (
                <Text color="#cccccc" fontSize="12px">
                  ราคารวม VAT 7%
                </Text>
              )}
            </Box>
            {service.plans.map(({ name, color, cost, unit }, index) => (
              <Box key={`head-${index}`} alignItems="center">
                {name && (
                  <Text
                    fontSize="12px"
                    textTransform="capitalize"
                    bg={color}
                    bgImage={color}
                    color="#ffffff"
                    borderBottomRightRadius="8px"
                    borderTopLeftRadius="8px"
                    p="2px 4px"
                    d="inline-block"
                  >
                    {name}
                  </Text>
                )}
                {cost && (
                  <Text
                    css={{
                      marginTop: 5,
                      fontSize: 16,
                      lineHeight: 0.88,

                      [mq('sm')]: {
                        fontSize: 20,
                      },
                    }}
                  >
                    {cost}
                    <span css={{ fontSize: 12 }}>
                      <Mobile>
                        <br />
                      </Mobile>
                      /{unit}
                    </span>
                  </Text>
                )}
              </Box>
            ))}

            {planBenefitContent.map(({ title, desc, benefit }, index) => (
              <Fragment key={`row-${index}`}>
                <Flex
                  minH="64px"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <Text
                    css={{
                      fontSize: 14,
                      [mq('sm')]: {
                        fontSize: 16,
                      },
                    }}
                  >
                    {title}
                  </Text>
                  <Text fontSize="12px">{desc}</Text>
                </Flex>
                {benefit.map(({ benefit: value, marginSize }, bindex) => (
                  <Flex key={`benefit-${index}-${bindex}`} alignItems="center">
                    <CheckIcon active={value} ml={marginSize || '14px'} />
                  </Flex>
                ))}
                <Box gridColumn="1 / -1" h="1px" bg="#e8e8e8" />
              </Fragment>
            ))}

            {service.type === 'consult' ? (
              <form
                as="form"
                onSubmit={consultingServiceForm.handleSubmit}
                css={{
                  gridColumn: '1 / -1',
                }}
              >
                <Grid rowGap="20px" pt="20px">
                  <FormControl
                    isRequired
                    isInvalid={!!consultingServiceForm.errors.email}
                  >
                    <FormLabel fontSize="16px">
                      กรอกอีเมลเพื่อรับรายละเอียด
                    </FormLabel>
                    <Input
                      name="email"
                      inputMode="email"
                      placeholder="อีเมลของคุณ"
                      value={consultingServiceForm.values.email}
                      onChange={consultingServiceForm.handleChange}
                      p="12px 16px"
                      h="48px"
                    />
                    {consultingServiceForm.errors.email && (
                      <FormErrorMessage>
                        {consultingServiceForm.errors.email}
                      </FormErrorMessage>
                    )}
                  </FormControl>

                  <FormControl
                    isRequired
                    isInvalid={!!consultingServiceForm.errors.tel}
                  >
                    <Input
                      name="tel"
                      type="tel"
                      placeholder="เบอร์ติดต่อ"
                      value={consultingServiceForm.values.tel}
                      onChange={(e) => {
                        const numericValue = e.target.value.replace(
                          TEL_REGEX,
                          '',
                        )
                        consultingServiceForm.setFieldValue('tel', numericValue)
                      }}
                      p="12px 16px"
                      h="48px"
                    />
                    {consultingServiceForm.errors.tel && (
                      <FormErrorMessage>
                        {consultingServiceForm.errors.tel}
                      </FormErrorMessage>
                    )}
                  </FormControl>

                  <Text
                    sx={{
                      a: {
                        color: service.color,
                      },
                    }}
                  >
                    <CKLink
                      isExternal
                      href="https://pdpacore.com/th/terms-of-use"
                    >
                      เงื่อนไขในการใช้เว็บไซต์
                    </CKLink>
                    &nbsp;และ&nbsp;
                    <CKLink
                      isExternal
                      href="https://pdpacore.com/th/privacy-policy"
                    >
                      นโยบายความเป็นส่วนตัว
                    </CKLink>
                  </Text>
                  <Button
                    as="button"
                    isLoading={loading}
                    type="submit"
                    sx={{
                      width: '100%',
                      color: 'white',
                      fontSize: {
                        base: 'lg',
                        md: '2xl',
                      },
                      backgroundColor: primaryColor || service.color,
                      '&:hover': {
                        backgroundColor: darken(
                          primaryColor || service.color,
                          10,
                        ),
                      },
                    }}
                  >
                    รับรายละเอียด
                  </Button>
                </Grid>
              </form>
            ) : service.type === 'prokit' ? (
              <Grid gridColumn="1 / -1" pt="20px" rowGap="20px">
                <Button
                  as="a"
                  sx={{
                    width: '100%',
                    color: 'white',
                    fontSize: {
                      base: 'lg',
                      md: '2xl',
                    },
                    backgroundColor: primaryColor || service.color,
                    '&:hover': {
                      backgroundColor: darken(
                        primaryColor || service.color,
                        10,
                      ),
                    },
                  }}
                  onClick={() => {
                    logEvent({
                      ga: {
                        category: 'Pricing',
                        action: 'Click learn more',
                        label: 'PDPA Prokit',
                      },
                    })
                    window.location.href = 'https://pdpa.pro/prokit'
                  }}
                >
                  เรียนรู้เพิ่มเติม
                </Button>
              </Grid>
            ) : (
              <Flex
                gridColumn="1 / -1"
                css={{
                  flexDirection: 'column',
                  padding: '24px 0',

                  [mq('sm')]: {
                    flexDirection: 'column-reverse',
                    padding: 0,
                  },
                }}
              >
                <Box>
                  {service.active ? (
                    <Link
                      route="selectPolicy"
                      params={{ template: service.type }}
                      passHref
                    >
                      <Button
                        as="a"
                        sx={{
                          width: '100%',
                          color: 'white',
                          fontSize: {
                            base: 'lg',
                            md: '2xl',
                          },
                          backgroundColor: primaryColor || service.color,
                          '&:hover': {
                            backgroundColor: darken(
                              primaryColor || service.color,
                              10,
                            ),
                          },
                        }}
                        onClick={() =>
                          logEvent({
                            ga: {
                              category: 'Pricing',
                              action: 'Click create policy',
                              label: getPolicyName(service.type),
                            },
                          })
                        }
                      >
                        {service.buttonText || 'สร้าง Policy ตอนนี้!'}
                      </Button>
                    </Link>
                  ) : (
                    <Button isDisabled css={{ width: '100%' }}>
                      {service.buttonText || 'สร้าง Policy ตอนนี้!'}&nbsp;
                      (กำลังมา)
                    </Button>
                  )}
                </Box>

                <Box
                  textAlign="center"
                  css={{
                    padding: '11px 0 0',
                    [mq('sm')]: {
                      padding: '24px 0',
                    },
                  }}
                >
                  <Text
                    color={service.color}
                    css={{
                      fontSize: 12,

                      [mq('sm')]: {
                        fontSize: 14,
                      },
                    }}
                  >
                    {service.aboveButtonText ||
                      'ใน 1 ฉบับ คุณจะได้รับ Policy ทั้งแบบภาษาไทยและภาษาอังกฤษ'}
                  </Text>
                </Box>
              </Flex>
            )}
          </Grid>
        </Grid>
      </Container>

      {!hideDecor && (
        <Fragment>
          <Box
            position="absolute"
            css={{
              zIndex: -1,
              [mq('md+')]: {
                width: 623,
                height: 552,
                top: -150,
                right: -450,
              },
            }}
          >
            <Background
              src="/images/Landing/img-blob.svg"
              objectFit="contain"
            />
          </Box>
          <Box
            position="absolute"
            zIndex="-1"
            css={{
              [mq('sm')]: {
                width: 411,
                height: 431,
                left: -300,
                top: 350,
              },
              [mq('md+')]: {
                left: -50,
                top: 350,
              },
              [mq('lg')]: {
                left: 250,
                top: 350,
              },
            }}
          >
            <Background
              src="/images/Landing/img-circles-1.svg"
              objectFit="contain"
            />
          </Box>
        </Fragment>
      )}
    </Box>
  )
}

export default OurServices
