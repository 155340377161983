import phone from 'phone'

export const TEL_REGEX = /[^0-9+]/g
const DEFAULT_COUNTRY_CODE = '+66'

export const validatePhone = (value: string | undefined | null): boolean => {
  if (!value) return false

  if (value.startsWith('+')) {
    const isInternationalThaiLandline =
      (value.startsWith('+6602') || value.startsWith('+662')) &&
      /^\+66(?:02|2)\d{7}$/.test(value)

    if (isInternationalThaiLandline) {
      return true
    }

    return phone(value).isValid
  }

  if (value.startsWith('02')) {
    return /^\d{9}$/.test(value)
  }

  if (value.startsWith('0')) {
    const phoneNumber = `${DEFAULT_COUNTRY_CODE}${value.slice(1)}`
    return phone(phoneNumber).isValid
  }

  return false
}
