import {
  Box,
  Button,
  chakra,
  Checkbox,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Image,
  Link,
  SimpleGrid,
  Text,
  VStack,
  FormErrorMessage,
} from '@chakra-ui/react'

import { Link as NextLink } from '@/lib/Link'
import { useCheckWebsiteForm } from './methods'
import { TEL_REGEX } from '@/lib/utils/validation/phoneValidation'

const Br = chakra('br')

const CheckWebsite = () => {
  const { formik, isLoading } = useCheckWebsiteForm()

  return (
    <Box
      id="CheckWebsite"
      sx={{
        background: 'DARK_BLUE',
        color: 'WHITE',
        paddingTop: { base: '72px', 'lg+': '104px' },
        paddingBottom: { base: '48px', md: '72px' },
      }}
    >
      <Image
        src="/images/Landing/head-checkwebsite.svg"
        sx={{
          width: { base: '80px', md: '120px', 'lg+': '160px' },
          position: 'absolute',
          left: '50%',
          transform: {
            base: 'translate(-50%, -109px)',
            md: 'translate(-50%, -127px)',
            'lg+': 'translate(-50%, -178px)',
          },
        }}
      />
      <Flex
        sx={{
          width: { base: '327px', md: '686px', 'lg+': '1114px' },
          mx: 'auto',
          position: 'relative',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Text
          sx={{ fontSize: { base: '24px', md: '40px' }, textAlign: 'center' }}
        >
          ตรวจสอบสถานะเว็บไซต์ของคุณให้สอดคล้องกับ&nbsp;
          <Text as="span" sx={{ color: '#00CEB5' }}>
            PDPA
          </Text>
          &nbsp;ฟรี !
        </Text>
        <Text
          sx={{
            alignSelf: 'center',
            textAlign: 'center',
            fontWeight: 300,
            mt: 2,
            fontSize: { base: '16px', md: '24px' },
          }}
        >
          เพียงกรอกเว็บไซต์และข้อมูลติดต่อของคุณ&nbsp;
          <Br display={{ base: 'block', 'lg+': 'none' }} />
          เราจะตรวจสอบและส่งรายงานให้คุณ
          <Br display={{ base: 'block', md: 'none' }} />
          ภายใน 5 วันทำการ
        </Text>

        <form onSubmit={formik.handleSubmit}>
          <FormControl isRequired isInvalid={!!formik.errors.website} mt={6}>
            <FormLabel>เว็บไซต์</FormLabel>
            <Input
              placeholder="เว็บไซต์"
              name="website"
              value={formik.values.website}
              onChange={formik.handleChange}
              inputMode="url"
              bg="white"
              color="black"
              fontWeight={300}
              border="1px solid #e8e8e8"
              h="48px"
              _placeholder={{
                color: 'gray.500',
                fontWeight: 300,
              }}
            />
            {formik.errors.website && (
              <FormErrorMessage>{formik.errors.website}</FormErrorMessage>
            )}
          </FormControl>
          <Text sx={{ mt: 2, fontWeight: 300 }}>
            ตัวอย่างเช่น http://www.yourwebsite.com
          </Text>

          <SimpleGrid columns={{ base: 1, md: 2 }} mt={6} gap={6}>
            <FormControl isRequired isInvalid={!!formik.errors.email}>
              <FormLabel>อีเมล</FormLabel>
              <Input
                placeholder="อีเมล"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                type="email"
                bg="white"
                color="black"
                fontWeight={300}
                border="1px solid #e8e8e8"
                h="48px"
                _placeholder={{
                  color: 'gray.500',
                  fontWeight: 300,
                }}
              />
              {formik.errors.email && (
                <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isRequired isInvalid={!!formik.errors.tel}>
              <FormLabel>เบอร์โทรศัพท์</FormLabel>
              <Input
                placeholder="เบอร์โทรศัพท์"
                name="tel"
                value={formik.values.tel}
                onChange={(e) => {
                  const numericValue = e.target.value.replace(TEL_REGEX, '')
                  formik.setFieldValue('tel', numericValue)
                }}
                type="tel"
                bg="white"
                color="black"
                fontWeight={300}
                border="1px solid #e8e8e8"
                h="48px"
                _placeholder={{
                  color: 'gray.500',
                  fontWeight: 300,
                }}
              />
              {formik.errors.tel && (
                <FormErrorMessage>{formik.errors.tel}</FormErrorMessage>
              )}
            </FormControl>
          </SimpleGrid>

          <VStack spacing={2} mt={6}>
            <FormControl
              isRequired
              isInvalid={!!formik.errors.terms_and_policy_consent}
            >
              <Checkbox
                size="lg"
                name="terms_and_policy_consent"
                alignItems="flex-start"
                sx={{
                  fontWeight: 300,
                  a: {
                    color: 'WHITE',
                    textDecoration: 'underline',
                    fontWeight: 500,
                  },
                }}
                isChecked={formik.values.terms_and_policy_consent}
                onChange={formik.handleChange}
              >
                ฉันยอมรับ&nbsp;
                <NextLink route="terms" passHref>
                  <Link isExternal>เงื่อนไขในการใช้เว็บไซต์</Link>
                </NextLink>
                &nbsp;และ&nbsp;
                <NextLink route="privacy" passHref>
                  <Link isExternal>นโยบายความเป็นส่วนตัว</Link>
                </NextLink>
              </Checkbox>
              {formik.errors.terms_and_policy_consent && (
                <FormErrorMessage>
                  {formik.errors.terms_and_policy_consent}
                </FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={!!formik.errors.marketing_consent}>
              <Checkbox
                size="lg"
                name="marketing_consent"
                alignItems="flex-start"
                sx={{
                  fontWeight: 300,
                  a: {
                    color: 'WHITE',
                    textDecoration: 'underline',
                  },
                }}
                isChecked={formik.values.marketing_consent}
                onChange={formik.handleChange}
              >
                ฉันต้องการรับโปรโมชันและข่าวสารทางการตลาดเกี่ยวกับ PDPA
                Pro&nbsp;
                <Br display={{ base: 'none', md: 'block', 'lg+': 'none' }} />
                และบริการอื่น ๆ จากเรา บริษัทในเครือ บริษัทย่อย
                และพันธมิตรทางธุรกิจ&nbsp;
                <Br display={{ base: 'block', md: 'none', 'lg+': 'block' }} />
                (คุณสามารถยกเลิกได้ทุกเมื่อ)
              </Checkbox>
              {formik.errors.marketing_consent && (
                <FormErrorMessage>
                  {formik.errors.marketing_consent}
                </FormErrorMessage>
              )}
            </FormControl>
          </VStack>

          <Center>
            <Button
              isLoading={isLoading}
              size="xl"
              colorScheme="primary"
              sx={{
                fontSize: '24px',
                mt: 6,
                w: { base: '327px', md: '354px' },
              }}
              type="submit"
            >
              ส่งข้อมูล
            </Button>
          </Center>
        </form>

        <Image
          src="/images/Landing/img-circles-3.svg"
          sx={{
            position: 'absolute',
            left: '100%',
            top: '100%',
            transform: 'translate(-10%, -50%)',
            display: { base: 'none', 'lg+': 'block' },
          }}
        />
      </Flex>
    </Box>
  )
}

export default CheckWebsite
