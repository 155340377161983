import { string, object, boolean } from 'yup'
import { validatePhone } from '@/lib/utils/validation/phoneValidation'

export const registrationFormSchema = object().shape({
  name: string().required('กรุณาระบุชื่อของคุณ'),
  email: string().email('อีเมล์ไม่ถูกต้อง').required('กรุณาระบุอีเมล์ของท่าน'),
  tel: string()
    .required('กรุณาระบุเบอร์โทรติดต่อของคุณ')
    .test('is-valid-phone', 'กรุณากรอกหมายเลขโทรศัพท์ที่ถูกต้อง', (value) => {
      return validatePhone(value)
    }),
  company: string(),
  message: string(),
  accept: boolean(),
  newsletter: boolean(),
})
