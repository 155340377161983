import { object, string, boolean } from 'yup'
import { validatePhone } from '@/lib/utils/validation/phoneValidation'

export const contactFormSchema = object().shape({
  first_name: string().required(),
  last_name: string().required(),
  company: string().required(),
  email: string().email().required(),
  tel: string()
    .required('กรุณาระบุเบอร์โทรติดต่อของคุณ')
    .test('is-valid-phone', 'กรุณากรอกหมายเลขโทรศัพท์ที่ถูกต้อง', (value) => {
      return validatePhone(value)
    }),
  terms_and_policy_consent: boolean().required(),
  marketing_consent: boolean(),
})
